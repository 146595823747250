import './App.css';
import {useEffect, useState} from "react";
import {Tooltip} from 'react-tooltip'

function App() {

    const hostname = "mc.gleich.org"

    const [status, setStatus] = useState({
        "status": 'loading',
        "icon": null,
        "online": 0,
        "max": 0,
        "list": [],
        "address": "mc.gleich.org",
        "motd": "Loading..."
    });

    const [timer, setTimer] = useState(null);

    function loadData() {
        console.log('load')
        fetch('https://api.mcsrvstat.us/2/' + hostname)
            .then((res) => res.json())
            .then((data) => {
                if (data.online) {
                    setStatus({
                        "status": 'online',
                        "icon": data.icon,
                        "online": data.players.online,
                        "max": data.players.max,
                        "list": Array.from(data.players.list),
                        "address": data.hostname,
                        "motd": data.motd.html
                    });
                } else {
                    setStatus({
                        "status": 'offline',
                        "icon": null,
                        "online": 0,
                        "max": 0,
                        "list": [],
                        "address": data.hostname,
                        "motd": "Offline"
                    });
                }
            })
            .catch(() => {
                setStatus({
                    "status": 'error',
                    "icon": null,
                    "online": 0,
                    "max": 0,
                    "list": [],
                    "address": hostname,
                    "motd": "Error"
                });
            });
    }

    useEffect(loadData, []);

    useEffect(() => {
        setTimer(setInterval(() => {
            loadData();
        }, 10000));
        return () => clearInterval(timer);
    }, [])

    return (
        <div>
            <div className="center main grid-container">
                <div className="icon"><Icon status={status}></Icon></div>
                <Players status={status}></Players>
                <div className="title">{status['address']}</div>
                <div className="motd">{status['motd']}</div>
            </div>
            {/*<div className="button">*/}
            {/*    <button onClick={loadData}>Reload</button>*/}
            {/*</div>*/}
        </div>
    );
}

function Icon(props) {
    if (props.status.status === 'online') {
        return <img src={props.status['icon']} alt="Server Image"></img>;
    }
    return <span className="error-server-icon material-symbols-outlined">signal_disconnected</span>;
}

function Players(props) {
    let status = props.status;
    if (status.status !== 'online') {
        return <div className="players"><span className="error-players material-symbols-outlined">cloud_off</span></div>
    }
    return (
        <>
            <div id="tooltip" className="players">{status['online']}/{status['max']}</div>
            <Tooltip anchorId="tooltip" place={"top"}>
                <ul>
                    {status.list.map(item => {
                        return (<li>{item}</li>);
                    })}
                </ul>
            </Tooltip>
        </>
    );
}

export default App;
